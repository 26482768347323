<template>
  <v-app style="background: white">
    <onebox_toolbar
      parentfolder
      @loadfile="loadfolder()"
      @callparentfolder="sendParentfolder()"
      @callstorage="loadstorage()"
    ></onebox_toolbar>
    <v-content>
      <v-card class="elevation-0">
        <v-divider></v-divider>
        <v-toolbar dense class="elevation-0">
          <v-avatar color="primary" size="35" class="mr-6">
            <v-icon dark size="27">star</v-icon>
          </v-avatar>
          <v-toolbar-title>
            <span
              style="color: #1565C0; font-size: 18px; font-weight: 600;"
            >&nbsp; {{ $t('toolbar.dcm')}}</span>
          </v-toolbar-title>

          <v-spacer></v-spacer>
          <!-- <v-toolbar-items>
           <v-btn fab small text @click="backtoroot()"><v-icon> keyboard_backspace </v-icon></v-btn>
          </v-toolbar-items>-->
        </v-toolbar>

        <div class="ma-2">
          <v-container fluid>
            <v-layout row wrap justify-center>
              <v-flex lg12 xs12>
                <v-expansion-panels v-model="panel">
                  <v-expansion-panel class="pa-1">
                    <v-expansion-panel-header>
                      <span
                        style="font-size: 18px; font-weight: 600;"
                      >{{ $t('dcmPage.conditionFile')}}</span>
                    </v-expansion-panel-header>
                    <v-expansion-panel-content class="pa-1">
                      <v-form>

                        <v-layout row wrap fill-height justify-center>
                          <v-flex xs12 sm12 md5 lg5 class="pa-1">
                          <v-container fluid>
                            <v-layout row wrap justify-center>
                              <v-flex md6 lg6 >
                                <v-dialog
                                  ref="dialogFromdateupload"
                                  v-model="modalFromdateupload"
                                  :return-value.sync="fromdateupload"
                                  persistent
                                  full-width
                                  width="290px"
                                >
                                  <template v-slot:activator="{ on }">
                                    <v-text-field
                                      color="primary"
                                      :label="$t('dcmPage.conditionOptional.fromdateupload')"
                                      v-model="fromdateupload"
                                      @click:prepend="modalFromdateupload = true"
                                      prepend-icon="event"
                                      v-on="on"
                                      readonly
                                      outlined
                                      dense
                                      clearable
                                      :persistent-hint="fromdate_hint"
                                      @click:clear="todateupload = ''"
                                      class="my-hint-style pa-1 pt-0 pb-0"
                                    ></v-text-field>
                                  </template>
                                  <v-date-picker
                                    v-model="fromdateupload"
                                    scrollable
                                    :locale="$t('default')"
                                    @input="changeFromdateupload()"
                                  >
                                    <v-spacer></v-spacer>
                                    <v-btn
                                      dark
                                      color="error"
                                      @click.stop="modalFromdateupload = false"
                                    >{{$t('dcmPage.conditionOptional.closefromdateupload')}}</v-btn>
                                  </v-date-picker>
                                </v-dialog>
                              </v-flex>
                               <v-flex md6 lg6>
                                <v-dialog
                                  ref="dialogTodateupload"
                                  v-model="modalTodateupload"
                                  :return-value.sync="todateupload"
                                  persistent
                                  full-width
                                  width="290px"
                                >
                                  <template v-slot:activator="{ on }">
                                    <v-text-field
                                      readonly
                                      color="primary"
                                      :label="$t('dcmPage.conditionOptional.todateupload')"
                                      v-model="todateupload"
                                      prepend-icon="event"
                                      @click:prepend="modalTodateupload = true"
                                      v-on="on"
                                      outlined
                                      dense
                                      clearable
                                      :persistent-hint="todate_hint"
                                      class="my-hint-style pa-1 pt-0 pb-0"
                                    ></v-text-field>
                                  </template>
                                  <v-date-picker
                                    v-model="todateupload"
                                    scrollable
                                    :locale="$t('default')"
                                    @input="$refs.dialogTodateupload.save(todateupload)"
                                    :min="fromdate"
                                  >
                                    <v-spacer></v-spacer>
                                    <v-btn
                                      dark
                                      color="error"
                                      @click.stop="modalTodate = false"
                                     >{{$t('dcmPage.conditionOptional.closetodateupload')}}</v-btn>
                                  </v-date-picker>
                                </v-dialog>
                              </v-flex>
                            </v-layout>
                             <v-layout row wrap justify-center>
                              <v-flex md6 lg6 >
                                <v-dialog
                                  ref="dialogFromdatedocument"
                                  v-model="modalFromdatedocument"
                                  :return-value.sync="fromdatedocument"
                                  persistent
                                  full-width
                                  width="290px"
                                >
                                  <template v-slot:activator="{ on }">
                                    <v-text-field
                                      color="primary"
                                      :label="$t('dcmPage.conditionOptional.fromdatedocument')"
                                      v-model="fromdatedocument"
                                      @click:prepend="modalFromdatedocument = true"
                                      prepend-icon="event"
                                      v-on="on"
                                      readonly
                                      outlined
                                      dense
                                      clearable
                                      :persistent-hint="fromdate_hint"
                                      @click:clear="todatedocument = ''"
                                      class="my-hint-style pa-1 pt-0 pb-0"
                                    ></v-text-field>
                                  </template>
                                  <v-date-picker
                                    v-model="fromdatedocument"
                                    scrollable
                                    :locale="$t('default')"
                                    @input="changeFromdatedocument()"
                                  >
                                    <v-spacer></v-spacer>
                                    <v-btn
                                      dark
                                      color="error"
                                      @click.stop="modalFromdatedocument = false"
                                    >{{$t('dcmPage.conditionOptional.closefromdatedocument')}}</v-btn>
                                  </v-date-picker>
                                </v-dialog>
                              </v-flex>
                               <v-flex md6 lg6>
                                <v-dialog
                                  ref="dialogTodatedocument"
                                  v-model="modalTodatedocument"
                                  :return-value.sync="todatedocument"
                                  persistent
                                  full-width
                                  width="290px"
                                >
                                  <template v-slot:activator="{ on }">
                                    <v-text-field
                                      readonly
                                      color="primary"
                                      :label="$t('dcmPage.conditionOptional.todatedocument')"
                                      v-model="todatedocument"
                                      prepend-icon="event"
                                      @click:prepend="modalTodatedocument = true"
                                      v-on="on"
                                      outlined
                                      dense
                                      clearable
                                      :persistent-hint="todate_hint"
                                      class="my-hint-style pa-1 pt-0 pb-0"
                                    ></v-text-field>
                                  </template>
                                  <v-date-picker
                                    v-model="todatedocument"
                                    scrollable
                                    :locale="$t('default')"
                                    @input="$refs.dialogTodatedocument.save(todatedocument)"
                                    :min="fromdatedocument"
                                  >
                                    <v-spacer></v-spacer>
                                    <v-btn
                                      dark
                                      color="error"
                                      @click.stop="modalTodatedocument = false"
                                     >{{$t('dcmPage.conditionOptional.closetodatedocument')}}</v-btn>
                                  </v-date-picker>
                                </v-dialog>
                              </v-flex>
                            </v-layout>
                             </v-container>
                          </v-flex>


                         
                          <v-flex xs12 sm12 md5 lg5 class="pa-1">
                           <v-container fluid>
                            <v-layout row wrap justify-center class="text-center">
                              <v-flex md12 lg12 >
                                ddd
                              </v-flex>
                            </v-layout>
                           </v-container>
                          </v-flex>
                        </v-layout>
                        
                      </v-form>
                    </v-expansion-panel-content>
                  </v-expansion-panel>
                </v-expansion-panels>
              </v-flex>
            </v-layout>
          </v-container>
        </div>
      </v-card>
    </v-content>
  </v-app>
</template>

<script>
const onebox_toolbar = () => import("../components/layout/layout-toolbar");
export default {
  name: "document-management",
  components: { onebox_toolbar },
  data: () => ({
    panel: "",
    fromdateupload: "",
    modalFromdateupload: false,
    todateupload: "",
    modalTodateupload: false,
    fromdatedocument: "",
    modalFromdatedocument: false,
    todatedocument: "",
    modalTodatedocument: false
  }),
  computed: {},
  methods: {
    changeFromdateupload() {
      this.$refs.dialogFromdateupload.save(this.fromdateupload);
      this.todateupload = "";
    },
    changeFromdatedocument() {
      this.$refs.dialogFromdatedocument.save(this.fromdatedocument);
      this.todatedocument = "";
    }
  },
  mounted() {
    //   console.log(this.$router.app['name'])
  }
};
</script>